@media (min-width:$width-Large){
	:root{
		--font-size:20px;
		--title-font-size:6rem;
		//Body Wrappers and Containers
		--bodyContainerPadding:25px;
		--galleryBodyContainerpadding-bottom:50px;

		//Body Containers Content
		--contentTitleFontSize: 30px;
		--contentPaddingTopBottom: 20px;
		--MainImageWidth: 70%;
		--MainImageHeight: 540px;
		--MainImageMarginTop: 10px;
		--AboutContactFontSize: 18px;
		
		//News Content
		--newsContainerBorderWidth: 4px;
		--newstitleFontSize: 22px;
		--newsTextFontSize: 16px;
		--newsTimeMarginRight: 5px;
		--newsTimeFontSize: 14px;
		
		//Gallery Content
		--galleryContainerHeight: 550px;	
	}
	
	#InfoPanel{
		--flex-direction:row;
		--container-margin:20px 50px;
	}

}
