#InfoPanel{
	color: var(--text1);
	flex-direction: var(--flex-direction);
	overflow: hidden;
}

.infoContainer{
	flex: 1;
	border: solid var(--brand) .3rem;
	border-radius: var(--borderRadius);
	margin: var(--container-margin);
	padding: .6rem .2rem 1rem;
	background-color: var(--background);
}

.infoContent{
	position: relative;
	width: auto;
	margin: 0px 1rem;
	.fa, .fas, .far{
		color:var(--brand)
	}
}

//-----------Contact Content----------

#Contact{
	a{
		margin-bottom:1rem;
	}
}

//-----------News Content------------
#News{
	.infoContent{
		display: flex;
		flex-direction: row;
		.newsContainer{
			flex:1;
			padding:0px 5px;
		}
		.newsDivide{
			width:1px;
			border-left: dashed var(--brand) .12rem;
		}
		.newsTime{
			font-size: .7rem;
		}
		.newsTitle{
			font-size: 1.15rem;
			margin: .5rem 0;
		}
	}
}

//-----------About Content------------
#About{
}
