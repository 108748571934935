
:root{
	--font-size:18px;
	--title-font-size:5rem;

	--directions-top:3rem;
	--directions-right:5rem;

	--borderRadius:20px;




	//Body Wrappers and Containers-------------------
	--bodyContainerPadding:25px 40px;
	--galleryBodyContainerpadding-bottom:50px;


	//Body Containers Content------------------------
	--contentTitleFontSize:32px;
	--contentPaddingTopBottom:25px;
	--MainImageWidth:95%;
	--MainImageHeight:290px;
	--MainImageMarginTop:10px;
	--AboutContactFontSize:20px;

	//News Content
	--newsContainerBorderWidth:4px;
	--newstitleFontSize:20px;
	--newsTextFontSize:16px;
	--newsTimeMarginRight:8px;
	--newsTimeFontSize:12px;

	//Gallery Content
	--galleryContainerHeight:350px;
}

#InfoPanel{
	--flex-direction:row;
	--container-margin:20px 10px;
}

#viewportData{
	position: fixed;
	top:5px;
	left:5px;
	width:200px;
	background-color: white;
	color:black;
	border: solid black 2px;
}