@media (max-width:($width-MSmall)) and (min-width:$width-Small + 1){
	:root{
		--font-size:16px;
		--title-font-size:4rem;
		
		--directions-top:2rem;
		--directions-right:2rem;
		
		--borderRadius:10px;

		//Body Wrappers and Containers-------------------
		--bodyContainerPadding:25px 15px;
		--galleryBodyContainerpadding-bottom:50px;


		//Body Containers Content------------------------
		--contentTitleFontSize:32px;
		--contentPaddingTopBottom:25px;
		--MainImageWidth:95%;
		--MainImageHeight:220px;
		--MainImageMarginTop:10px;
		--AboutContactFontSize:18px;

		//News Content
		--newsContainerBorderWidth:4px;
		--newstitleFontSize:18px;
		--newsTextFontSize:14px;
		--newsTimeMarginRight:3px;
		--newsTimeFontSize:12px;

		//Gallery Content
		--galleryContainerHeight:215px;
	}
	#InfoPanel{
		--flex-direction: column;
		--container-margin: 10px;
	}
}
