/* brand foundation */
$brand-hue: 217;
$brand-hue2: 60;
$brand-saturation: 100%;
$brand-lightness: 50%;

/* light */
$brand-light: hsl($brand-hue $brand-saturation $brand-lightness);
$text1-light: hsl($brand-hue $brand-saturation 10%);
$text2-light: hsl($brand-hue 30% 30%);
$text3-light: hsl($brand-hue 0% 95%);
$surface1-light: hsl($brand-hue 10% 90%);
$surface2-light: hsl($brand-hue 95% 65%);
$surface3-light: hsl($brand-hue 95% 45%);
$surface4-light: hsl($brand-hue 80% 20%);
$surface-shadow-light: hsla($brand-hue, 10%, 20%,.5);
$shadow-strength-light: .02;
$background-light:$surface1-light;

/* dark */
$brand-dark: hsl($brand-hue calc(#{$brand-saturation} / 1.2) calc(#{$brand-lightness} / 1.5));
$text1-dark: hsl($brand-hue 30% 20%);
$text2-dark: hsl($brand-hue 5% 65%);
$text3-dark: hsl($brand-hue 15% 85%);
$surface1-dark: hsl($brand-hue 10% 10%);
$surface2-dark: hsl($brand-hue 10% 22%);
$surface3-dark: hsl($brand-hue 5%  40%);
$surface4-dark: hsl($brand-hue 5% 50%);
$surface-shadow-dark: hsl($brand-hue 50% 3%);
$shadow-strength-dark: .8;
$background-dark:$surface1-dark;

:root {
	color-scheme: light;
	/* set defaults */
	--brand: #{$brand-light};
	--text1: #{$text1-light};
	--text2: #{$text2-light};
	--text3: #{$text3-light};
	--surface1: #{$surface1-light};
	--surface2: #{$surface2-light};
	--surface3: #{$surface3-light};
	--surface4: #{$surface4-light};
	--surface-shadow: #{$surface-shadow-light};
	--shadow-strength: #{$shadow-strength-light};
	--background: #{$background-light};
}

/*
@media (prefers-color-scheme: light) {
	:root {
		color-scheme: light;
		--brand: #{$brand-light};
		--text1: #{$text1-light};
		--text2: #{$text2-light};
		--background: #{$background-light};
		--surface1: #{$surface1-light};
		--surface2: #{$surface2-light};
		--surface3: #{$surface3-light};
		--surface4: #{$surface4-light};
		--surface-shadow: #{$surface-shadow-light};
		--shadow-strength: #{$shadow-strength-light};
	}
}

@media (prefers-color-scheme: dark) {
	:root {
		color-scheme: dark;
		--brand: #{$brand-dark};
		--text1: #{$text1-dark};
		--text2: #{$text2-dark};
		--background: #{$background-dark};
		--surface1: #{$surface1-dark};
		--surface2: #{$surface2-dark};
		--surface3: #{$surface3-dark};
		--surface4: #{$surface4-dark};
		--surface-shadow: #{$surface-shadow-dark};
		--shadow-strength: #{$shadow-strength-dark};
	}
}
*/

[color-scheme="light"] {
	color-scheme: light;
	--brand: #{$brand-light};
	--text1: #{$text1-light};
	--text2: #{$text2-light};
	--text3: #{$text3-light};
	--background: #{$background-light};
	--surface1: #{$surface1-light};
	--surface2: #{$surface2-light};
	--surface3: #{$surface3-light};
	--surface4: #{$surface4-light};
	--surface-shadow: #{$surface-shadow-light};
	--shadow-strength: #{$shadow-strength-light};
}

[color-scheme="dark"] {
	color-scheme: dark;
	--brand: #{$brand-dark};
	--text1: #{$text1-dark};
	--text2: #{$text2-dark};
	--text3: #{$text3-dark};
	--background: #{$background-dark};
	--surface1: #{$surface1-dark};
	--surface2: #{$surface2-dark};
	--surface3: #{$surface3-dark};
	--surface4: #{$surface4-dark};
	--surface-shadow: #{$surface-shadow-dark};
	--shadow-strength: #{$shadow-strength-dark};
}