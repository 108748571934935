@import '@CSS/helper/appVars';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

$width-Large:1650px;
$width-MLarge:1100px;
$width-MSmall:800px;
$width-Small:620px;

* {
	box-sizing: border-box;
	margin: 0;
	text-align: center;
}

:root{
	font-size:var(--font-size);
}

html{
  	block-size: 100%;
}
body{
	color:var(--text1);
	font-family: "Georgia",serif;
	padding:0;
	background-color:var(--background);
}

button, input[type=submit], input[type=button], input[type=filter]{
	cursor: pointer;
}

a, a:visited{
	color:var(--text1);
}
a:active{
	color:var(--text2);
} 
a:hover{
	text-decoration: none;
	color:var(--text1);
}
.underline,a:not([href]), a:not([href]):hover{
	text-decoration: underline;
	cursor: pointer;
}