#GalleryBodyContainer{
	padding: var(--bodyContainerPadding);
	overflow: hidden;
}

#GalleryContent{
	--gap:0px;
	display: grid;
	grid-auto-flow: column;
	gap: calc(var(--gap) / 2);
	margin: 0;
	border-radius: var(--borderRadius);

	padding-inline-start: var(--gap);
	padding-inline-end: var(--gap);
	padding-block-start: calc(var(--gap) / 2);
	padding-block-end: calc(var(--gap) / 2);

	overflow-x: auto;
	overflow-y: hidden;
	scroll-snap-type: inline mandatory;
	scroll-padding-left: var(--gap);
	scroll-padding-right: var(--gap);
	scroll-padding-inline: var(--gap);
}

#Gallery{
	width:100%;
	margin-left: auto;
	margin-right: auto;
}

.galleryImageLink{
	padding: 0px 10px;
    height: var(--galleryContainerHeight);
}

.galleryImage {
	img{
		max-height: 100%;
		display: grid;
		margin: 0;
		cursor: pointer;
		user-select: none;
		border-radius: var(--borderRadius);
	}
}
