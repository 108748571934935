@import '~@CSS/app';

@import '~@CSS/index/vars/default';
@import '~@CSS/index/vars/mLarge';
@import '~@CSS/index/vars/large';
@import '~@CSS/index/vars/mSmall';
@import '~@CSS/index/vars/small';

@import "~@CSS/index/mainPanel";
@import "~@CSS/index/infoContainers";
@import "~@CSS/index/gallery";

.panel{
	display:flex;
	align-content: center;
	justify-content: center;
	width:100%;
}

.panelImage{
	display: block;
	position: absolute;
	width: 100%;
	height: 100vh;
	background-repeat: no-repeat; 
	background-position: center center; 
	background-size: cover;
	z-index: -50;
}

.panelTitle{
	font-size: 2rem;
	margin: 0px auto 1rem;
	text-align: center;
	width:100%;
	.underBorderline{
		position: relative;
		bottom: -4px;
		margin: 0 auto;
		width: 200px;
		border-bottom: solid var(--brand) 4px;
	}
}

.loadingGif{
	width:5rem;
	display: block;
	margin: 0 auto;
	animation: loadingSpin 1s linear infinite;
}

@keyframes loadingSpin { 100% { transform:rotate(720deg); } }

a, a:visited, a:hover, a:active {
	color: inherit;
	text-decoration: none;
	display: block;
}