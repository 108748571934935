#MainPanel{
	display:grid;
	height:100vh;
	color:var(--text3);
	background-color: rgba(0,0,0,.5);
	.panelImage{
		background-image: url('~@Images/titleImage.jpg');
	}
}

h1.panelTitle{
	font-size: var(--title-font-size);
	margin-bottom: 25vh;
}

#DirectionsLink{
	position: absolute;
	display: block;
	padding:1rem 0rem;
	width: 11rem;
	top: var(--directions-top);
	right: var(--directions-right);
	border-radius: var(--borderRadius);
	border: solid var(--brand) 6px;
	.fa-map-marker-alt{
		margin-left:7px;
	}
}
#DirectionsLink:hover{
	background-color: var(--brand);
}